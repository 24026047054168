@use "sass:map";
$primary-color: #64ff00;
$baseFontSize: 16;

$designScreens: (
  "lg": 1920,
  "sm": 375,
);

$minDesktop: 1280;
$desktopMultiplier: 1920 / 1280 * 16;

$minTablet: 768;
$tabletMultiplier: 1920 / 768 * 16;

$mobile-design-width: map-get(
  $map: $designScreens,
  $key: "sm",
);
$desktop-design-width: map-get(
  $map: $designScreens,
  $key: "lg",
);

$screens: (
  "lg": 1200,
  "md": 768,
  "sm": 360,
);
$mobile-width: map-get(
  $map: $screens,
  $key: "sm",
);
$tablet-width: map-get(
  $map: $screens,
  $key: "md",
);
$desktop-width: map-get(
  $map: $screens,
  $key: "lg",
);

@function toRemDesktop($value: 16) {
  $remValue: ($value / $desktopMultiplier) + rem;
  @return $remValue;
}
@function toRemTablet($value: 16) {
  $remValue: ($value / $tabletMultiplier) + rem;
  @return $remValue;
}
@function toRem($value: 16) {
  $remValue: ($value / 16) + rem;
  @return $remValue;
}

@function toVwDesktop($value: 16) {
  $vwValue: ($value / $desktop-design-width) * 100 + vw;
  @return $vwValue;
}
@function toVw($value: 16) {
  $vwValue: ($value / $mobile-design-width) * 100 + vw;
  @return $vwValue;
}

@mixin desktop {
  @media (min-width: #{$desktop-width}px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$desktop-width}px) {
    @content;
  }
}
