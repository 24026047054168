@import '@/styles/_global';
.root {
  // padding: 1.75rem 1.5rem;
  &.card {
    border-radius: toRem(16);
    background: var(--N-White, #fff);
    box-shadow: toRem(5) toRem(5) toRem(50) toRem(0) rgba(199, 199, 199, 0.35);
  }
  &.wide {
    padding-bottom: 0;
  }
}

.title {
  color: var(--P-Blue, #1a325d);
  font-family: var(--font-baskervville);
  font-size: toRem(32);
  font-style: normal;
  font-weight: 400;
  line-height: toRem(35);
  letter-spacing: -3%;
  text-transform: capitalize;
  padding-bottom: toRem(16);

  // Default styles for title on mobile
  @include mobile() {
    font-size: 1.3rem;
  }

  @include desktop() {
    font-size: toRemDesktop(54);
    line-height: toRemDesktop(68);
    letter-spacing: -3%;
    padding-bottom: 0;
  }
}
.careerService {
  .title {
    font-size: 1.5rem;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
}
.subtitle {
  color: var(--N-Grey-Dark, #666666);
  font-family: var(--font-raleway);
  font-size: toRem(16);
  font-style: normal;
  font-weight: 500;
  line-height: toRem(22); /* 137.5% */
  letter-spacing: 2%;

  a {
    color: var(--primary-blue);
    text-decoration-line: underline;
  }
}
.hr {
  margin-top: toRem(24);
  height: 1px;
  background: #d4d4d4;
}
.cta {
  margin-top: toRem(32);
  display: flex;
  justify-content: center;
}

.ctaBtn {
  font-size: toRem(20);
  white-space: nowrap;
}

.ctaBottom {
  margin-bottom: toRem(10);
}

@include desktop() {
  .root {
    padding: 2rem 0rem;
    &.card {
      border-radius: toRemDesktop(32);
      background: var(--N-White, #fff);
      box-shadow: toRemDesktop(5) toRemDesktop(5) toRemDesktop(50)
        toRemDesktop(0) rgba(199, 199, 199, 0.35);
    }
    &.wide {
      padding-bottom: 0;
    }
  }

  .title {
    line-height: toRemDesktop(68); /* 105.741% */
    letter-spacing: -3%;
    padding-bottom: 0;
  }
  .subtitle {
    color: var(--N-Grey-Dark, #686868);
    text-align: right;
    font-size: toRemDesktop(20);
    line-height: toRemDesktop(28);
    margin-left: 3rem;
  }
  .hr {
    margin-top: toRemDesktop(54);
    // margin-bottom: toRemDesktop(54);
  }
  .cta {
    margin-top: toRemDesktop(75);
  }

  .ctaBtn {
    font-size: toRemDesktop(20);
  }

  .facultySection {
    background-color: #1a325d;  
    padding: 1rem;
    overflow-x: hidden;  
    .card {
      background-color: #1a325d; 
    }
  }
}
@include mobile(){
  .title {
    font-size: 1.3rem;
  }
  .subtitle{
    text-align: left;
    font-size: 0.7rem;
  }
}

.mobileCTA{
  margin: 0 !important;
  justify-content: left;
}
