@import '@/styles/_global';
.stripContainer{
  background-color: #ffc907;
  text-align: center;
}

@include desktop() {
  .stripContainer {
    margin-bottom: toRem(12);
    padding: toRem(10) toRem(20);
    font-size: toRem(14);
    border-radius: toRem(12);
  }
}

@include mobile() {
  .stripContainer {
    margin-bottom: toRem(12);
    padding: toRem(8) toRem(16);
    font-size: toRem(12);
    border-radius: toRem(4);
  }
}
