@import '@/styles/_global';
.container {

    margin-top: toRem(16);
  
}
.marquee {
  width: 100%;
  line-height: toRem(38);
  background-color: red;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee  {
  p{
    display: inline-block;
    padding-left: 100%;
    animation: marquee 40s linear infinite;
    margin-right: toRem(24);

    span{
      margin-right: toRem(100);
    }
  }
  
}

@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}


@include mobile(){
  .marquee  {
    p{
      display: inline-block;
      padding-left: 100%;
      animation: marquee 40s linear infinite;
      margin-right: toRem(24);
  
      span{
        margin-right: toRem(100);
      }
    }
  }

}