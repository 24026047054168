@import '@/styles/_global';

.root {
  position: relative;
  border-radius: toRem(16);
  padding: toRem(30) toRem(38);
  min-height: 11rem;
  justify-content: flex-end;
  overflow: hidden;
  background-color: var(--background-color);
  color: var(--color);
  font-family: var(--font-raleway);
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  &:hover{
    transform: rotateX(180deg);
  }
  .frontSide{
    position: relative;
    transition-duration: 0.3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .backSide{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffc907;
    backface-visibility: hidden;
    background-color: #ffc907;
    transform: rotateX(180deg);
  }
  img {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-height: 70%;
    aspect-ratio: 2/3;
    object-fit: contain;
    object-position: center;
  }
}

.border {
  border: 1.5px solid var(--primary-blue);
}

.title {
  // color: var(--P-Blue, #1a325d);
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 800;
  font-size: toRem(25);
  line-height: toRem(30); /* 130.556% */
  z-index: 2;
  position: relative;
}

.outline {
  background-color: transparent; 
  border-style: solid;
  border-color: var(--color);
  border-width: 4px;
}

.titleWithSubtitle {
  display: flex;
  align-items: baseline;

  h6 {
    margin-left: toRem(6);
    font-size: toRem(24);
    line-height: toRem(48);
  }
}

.titleDescription {
  position: relative;
  font-size: toRem(16); 
  line-height: toRem(24);
  z-index: 2;
  margin-left: 0.5rem;
}

.visibility{
  visibility: hidden;
}
.content {
  // color: var(--P-Blue, #1a325d);
  font-size: 14px;
  font-weight: 500;
  line-height: toRem(30.4); /* 124.427% */
  letter-spacing: toRem(0.48);
  // max-width: 80%;
}

.contentParent{
   position: absolute;
   bottom: 10%;
   z-index: 2;
}

.announcementCard {
  background-color: transparent;
  perspective: 1000px;
  &:hover .announcementInner {
    transform: rotateX(180deg);
  }
}

.announcementInner {
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;

  img {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-height: 70%;
    aspect-ratio: 2/3;
    object-fit: contain;
    object-position: center;
  }

}

.announcementInner.flip {
  transform: rotateX(180deg);
}

.announcementItem {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  position: relative;
  border-width: 0.333rem;
  border-style: solid;
  border-color: #ffc907;
  padding: 1rem 1.5rem;
  justify-content: space-between;
  height: 100%;
  min-height: 120px;
  color: #1a325d;
  transition-duration: 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.announcementItem {
  height: 100px;
  padding: 0.8rem;
  min-height: 120px;
}

.announcementTitle {
  position: relative;
  z-index: 1;

  h2 {
    font-size: 36px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0px;
  }

  h4 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0px;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
  }
}

.announcementSubtitle {
  position: relative;
  z-index: 1;

  h3 {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}

.announcementImg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  padding: 10px 0px;

  img {
    height: 100%;
  }
}

.announcementImg {
  display: none;
}

.announcementCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #ffc907;
  transform: rotateX(180deg);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: #1a325d;
  z-index: 2;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    margin: 0px;
  }
}

.underDesc {
  padding: 1rem;
}
@include mobile(){
  .announcementInner{
   > img {
    display: none;
   } 
  }


@include mobile() {
  .announcementCard {
    background-color: transparent;
    perspective: 1000px;
    position: relative;
  }

  .announcementInner {
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    // animation: flip-sequence 8s infinite;
  }

  .flip {
    transform: rotate(180deg);
  }

.announcementCard:nth-child(2) .announcementInner {
    animation-delay: 2s; /* Starts after the first card's pause */
}

.announcementCard:nth-child(3) .announcementInner {
    animation-delay: 4s; /* Starts after the second card's pause */
}

.announcementCard:nth-child(4) .announcementInner {
    animation-delay: 6s; /* Starts after the third card's pause */
}
}
  
  .content {
    z-index: 1;
    font-size: toRemDesktop(21);
    font-weight: 600;
    line-height: toRemDesktop(20);
    letter-spacing: toRemDesktop(0.56);
    padding: 0px 8px 0px 0px;
  }

  .title {
    font-variant-numeric: lining-nums proportional-nums;
    font-weight: 800;
    font-size: toRem(19);
    line-height: toRem(20); /* 130.556% */
    z-index: 2;
    position: relative;
  }

  .announcementCardBack {
    font-size: 0.7rem;
    line-height: 14px;
  }

  .announcementItem {
    border-width: 0.2rem;
  }
}

@include desktop() {
  .root {
    border-radius: toRemDesktop(32);
    min-height: 10rem;
    padding: toRemDesktop(40) toRemDesktop(37) toRemDesktop(42) toRemDesktop(48);
  }
  .title {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: toRemDesktop(35);
    font-weight: 800;
    line-height: toRemDesktop(36);
  }
  .content {
    font-size: 14px;
    font-weight: 600;
    line-height: toRemDesktop(32); /* 114.286% */
    letter-spacing: toRemDesktop(0.56);
  }
  .titleDescription {
    position: relative;
    z-index: 2;
  }
}
