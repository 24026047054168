@import '@/styles/_global';
.root {
    display: grid;
    row-gap: toRem(12);
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
  }

  @include mobile() {
    .root {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }
  @include desktop() {
    .root {
      padding: 1rem 0rem;
      column-gap: toRemDesktop(24);
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  