@import '@/styles/_global';
.root {
  border-radius: toRem(16);
  box-shadow: toRem(-4) toRem(4) toRem(10) toRem(0) rgba(199, 199, 199, 0.25);
}
@include desktop() {
  .root {
    border-radius: toRemDesktop(32);
    box-shadow: toRemDesktop(-4) toRemDesktop(4) toRemDesktop(54) 0px
      rgba(0, 0, 0, 0.15);
  }
}
