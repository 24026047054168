@import '@/styles/_global';
@include desktop() {
  .btnContainer {
    padding: 0.50rem 1.1333333333rem;
    color: #1a325d;
    border-color: #1a325d;
    background: #ffc907;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 1.5px solid #1a325d;
    font-weight: 600;
    font-family: var(--font-raleway);
   

    span {
      margin-right: 0.5rem;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.btnText{
  text-transform: uppercase;
}


@include mobile() {
    .btnContainer {
      padding: 0.50rem 1.1333333333rem;
      color: #1a325d;
      border-color: #1a325d;
      background: #ffc907;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      border: 1.5px solid #1a325d;
      font-weight: 600;
      font-family: var(--font-raleway);
     
  
      span {
        margin-right: 0.5rem;
        font-size: 1rem;
      }
    }
  }
  
  
  @include mobile() {
    .btnContainer {
      padding: 0.60rem;
      color: #1a325d;
      border-color: #1a325d;
      background: #ffc907;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      border: 1.5px solid #1a325d;
      font-weight: 600;
      font-family: var(--font-raleway);
    
  
      span {
        margin-right: 0.4rem;
        font-size: 0.8rem;
      }
    }
  
  }
