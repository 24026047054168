@import '@/styles/_global';
  .root {
    padding: 1.75rem 1.5rem;
  }
  .hr {
    margin-top: toRem(24);
    height: 2px;
    background: #d4d4d4;
    margin-bottom: toRem(30);
  }
  .item {
    img {
      width: toRem(70);
      height: toRem(70);
      margin-bottom: toRem(9);
      aspect-ratio: 1 / 1;
    }
    p {
      font-size: toRem(14);
      font-style: normal;
      font-weight: 500;
      line-height: toRem(17.5);
      letter-spacing: 3%;
    }
  }
  .CarousalWrapper{
    padding-bottom: toRem(32);
  }
  
  @include desktop() {
    .root {
      min-height: toRemDesktop(578);
      padding: 1.75rem 0rem;
    }
    .item {
      display: flex;
      align-items: center;
  
      img {
        width: toRemDesktop(134);
        height: toRemDesktop(134);
        margin-right: toRemDesktop(21.6);
        margin-bottom: 0;
      }
      p {
        flex-grow: 1;
        font-style: normal;
        font-weight: 500;
        font-size: toRemDesktop(24);
        line-height: toRemDesktop(28); /* 116.667% */
        letter-spacing: 2%;
      }
    }
    .CarousalWrapper{
      padding-bottom: toRemDesktop(75);
    }
  }

  @include mobile(){
    .Carousel__blueBullet .swiper-pagination{
      position: absolute !important;
    }
  }
